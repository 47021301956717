/* @import url('https://cdn.syncfusion.com/ej2/material.css'); */
@import url('https://fonts.googleapis.com/css2?family=Sevillana&display=swap');

/* .sidebar {
  box-shadow: rgb(113 122 131 / 11%) 0px 7px 30px 0px;
}

.nav-item,
.navbar {
  z-index: 10000;
}

@media screen and (max-width:800px) {
  .sidebar {
    z-index: 10000000;
  }
}

.e-dlg-center-center,
.e-quick-popup-wrapper.e-device {
  z-index: 1000000 !important;
} */

::-webkit-scrollbar {
  width: 3px;
  /* display: none; */
}

::-webkit-scrollbar-thumb {
  background-color: rgb(216, 216, 216);
  border-radius: 40px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

/* color-picker style  */

/* #preview {
  background: transparent url('https://ej2.syncfusion.com/react/demos/src/color-picker/images/pen.png') no-repeat;
  display: inline-block;
  height: 80px;
  margin: 10px 0;
  min-width: 300px;
  max-width: 600px;
  background-color: #008000;
}

.e-input-group:not(.e-float-icon-left),
.e-input-group.e-success:not(.e-float-icon-left),
.e-input-group.e-warning:not(.e-float-icon-left),
.e-input-group.e-error:not(.e-float-icon-left),
.e-input-group.e-control-wrapper:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-success:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-warning:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-error:not(.e-float-icon-left) {
  border: none;
} */

body {
  /* font-family: 'Sevillana', cursive; */
  /* height: 100%;
  display: flex;
  flex-direction: column; */
  /* min-height: 100vh; */
}

#footer-div {
  color: whitesmoke;
  background: url('./images/bg.webp');
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  bottom: 0;
  width: 100%;
}

/* #footer-div::before {
  content: "";
  background: url('./images/bg.webp');
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  height: 100%;
  z-index: -5;
} */

#appbar {
  color: whitesmoke;
  background: url('./images/bg.webp');
  background-repeat: no-repeat;
  background-size: cover;
}

#parent {
  position: relative;
  min-height: 100vh;
}
